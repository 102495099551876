import { useRef, useReducer, useState } from 'react'
import { useModalContext } from '@/context/ModalContext'
import { useCustomerContext } from '@/context/CustomerContext'
import { accountFormReducer, initialState } from '@/utils/account'
import classes from './AccountForm.module.scss'
import { useRouter } from 'next/router'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { validateEmail } from '@/utils/validation'

const LoginAccountForm = ({ redirect, isCheckout, onForgotPasswordClick }) => {
  const router = useRouter()
  const modalContext = useModalContext()
  const customerContext = useCustomerContext()
  const emailRef = useRef()
  const passwordRef = useRef()
  const [state, dispatch] = useReducer(accountFormReducer, initialState)
  const { showErrorMessage, errorMessage, isLoading } = state
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const title = isCheckout ? (
    <h3>Login to Apply Discounts</h3>
  ) : (
    <>
      <h4>Log in To Your Sitka Seafood Member Portal</h4>
      <h5>
        Track orders and manage your
        <br /> subscription in your account.
      </h5>
    </>
  )

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (!validateEmail(email)) {
      dispatch({ type: 'error', payload: 'Please enter a valid email address.' });
      return;
    }

    dispatch({ type: 'loading' })

    const response = await customerContext.login({
      email,
      password,
    })
    if (response.errors?.length) {
      let customErrorMessage = response.errors[0].message
      if (customErrorMessage === 'Unidentified customer') {
        customErrorMessage = 'Invalid email or password'
      }
      dispatch({ type: 'error', payload: customErrorMessage })
    } else {
      console.debug('customer login success', response)
      if (redirect) {
        router.push(redirect)
      } else {
        modalContext.setIsOpen(false)
      }
    }
  }

  return (
    <div
      className={`${classes['account-form']} ${isCheckout ? classes['account-form--checkout'] : ''}`}
    >
      {title}
      <form onSubmit={(e) => onSubmit(e)}>
        {showErrorMessage && (
          <p className={classes['account-form__error']}>{errorMessage}</p>
        )}
        <div className="input-group">
          <input
            data-testid="login-email-input"
            type="email"
            className="input"
            placeholder="email address"
            ref={emailRef}
          />
        </div>
        <div className={` ${classes['password-group']}`}>
          <input
            data-testid="login-password-input"
            type={isPasswordVisible ? 'text' : 'password'}
            className="input"
            placeholder="password"
            ref={passwordRef}
          />
          <span 
            onClick={togglePasswordVisibility}
            className={classes['password-toggle-icon']}
            data-testid="login-password-toggle"
            >
            {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
          </span>
        </div>
        {isCheckout && onForgotPasswordClick ? (
          <div className={classes['account-form-btn-wrapper']}>
            <button className="btn sitkablue" disabled={isLoading}>
              Login
            </button>
            <button
              onClick={() => onForgotPasswordClick()}
              className="btn-link-underline forgot-password-button"
            >
              Forgot Your Password?
            </button>
          </div>
        ) : (
          <button className="btn sitkablue" disabled={isLoading}>
            Login
          </button>
        )}
      </form>

      {!isCheckout && (
        <>
          <p>
            <button
              onClick={() => {
                modalContext.setIsOpen(true)
                modalContext.setModalType('forgot_password')
              }}
              className="btn-link-underline"
            >
              Forgot Password?
            </button>
          </p>
          <p>
            {`Don't have an account? `}
            <button
              onClick={() => {
                modalContext.setIsOpen(true)
                modalContext.setModalType('create')
              }}
              className="btn-link-underline"
            >
              Sign Up
            </button>
          </p>
        </>
      )}
    </div>
  )
}

export default LoginAccountForm
