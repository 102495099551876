import Image from 'next/image'
import { useRouter } from 'next/router'
import Link from 'next/link'
import imageUrlBuilder from '@sanity/image-url'
import classes from './BannerBlock.module.scss'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import sanityClient from '@/services/sanityClient'

const BannerBlock = ({ fields }) => {
  const router = useRouter()
  if (!fields) {
    return <></>
  }

  const {
    image,
    header,
    subheader,
    ctaText,
    ctaUrl,
    backgroundColor,
    headerColor,
    subHeaderColor,
    footerImage,
    footerText,
    footerSubText,
  } = fields
  const id = fields?.title?.replace(' ', '-')?.toLowerCase()
  const builder = imageUrlBuilder(sanityClient)

  function urlFor(source) {
    return builder.image(source)
  }

  function cropImageUrl(image) {
    if (!image) return
    if (!image?.asset?.metadata?.dimensions) {
      return image.asset?.url
    }
    const h = image.asset.metadata.dimensions.height
    const w = image.asset.metadata.dimensions.width
    return image.asset.url
      ? image.hotspot
        ? urlFor(image)
            .rect(
              parseInt(image.crop.left * w),
              parseInt(image.crop.top * h),
              parseInt(image.hotspot.width * w),
              parseInt(image.hotspot.height * h),
            )
            .fit('max')
            .auto('format')
            .url()
        : image.asset.url
      : undefined
  }

  return (
    <div
      id={id || ''}
      className={`${classes['banner']}`}
      style={{ backgroundColor: backgroundColor?.hex }}
    >
      {(image || header || subheader) && (
        <div
          className={`${classes['banner__row']} ${classes['banner__block']} ${classes['justify-center']}`}
          onClick={() => {
            router.push(ctaUrl)
          }}
        >
          {image && (
            <ResponsiveComponent
              desktopBreakpoint={'xs'}
              className={`${classes['banner__wrap']} ${classes['banner__image']}`}
              mobileClassName={classes['banner__wrap--mbl']}
              desktopClassName={classes['banner__wrap--dsktp']}
              mobile={
                <Image
                  className={classes.dsktp__img}
                  src={cropImageUrl(image)}
                  alt={image.alt}
                  fill
                />
              }
              desktop={
                <Image
                  className={classes.mbl__img}
                  src={cropImageUrl(image)}
                  alt={image.alt}
                  fill
                />
              }
            />
          )}
          <div className={classes['banner__text--title']}>
            {header && <h1 style={{ color: headerColor?.hex }}>{header}</h1>}
            {subheader && (
              <h2
                style={{ color: subHeaderColor?.hex }}
                className={classes['subheader']}
              >
                {subheader}
              </h2>
            )}
          </div>
        </div>
      )}
      {(footerImage || footerText || footerSubText || ctaText) && (
        <div
          className={`${classes['banner__row']} ${classes['banner__footer']} ${(image || header || subheader) && classes['footer-margin']} ${classes['justify-center']}`}
        >
          {footerImage && (
            <ResponsiveComponent
              desktopBreakpoint={'xs'}
              className={`${classes['banner__wrap']} ${classes['banner__image1']}`}
              mobileClassName={classes['banner__wrap--mbl']}
              desktopClassName={classes['banner__wrap--dsktp']}
              mobile={
                <Image
                  sizes="100vw"
                  className={classes.dsktp__img}
                  src={cropImageUrl(footerImage)}
                  layout="fill"
                  alt={footerImage.alt}
                />
              }
              desktop={
                <Image
                  className={classes.mbl__img}
                  src={cropImageUrl(footerImage)}
                  layout="fill"
                  alt={footerImage.alt}
                />
              }
            />
          )}
          <div className={classes['banner__footer--text']}>
            {footerText && <h1>{footerText}</h1>}
            {footerSubText && (
              <h2 className={classes['subheader']}>{footerSubText}</h2>
            )}
          </div>

          {ctaUrl && (
            <>
              <div className={classes['divider']} />

              <div className={classes['btn-wrap']}>
                <Link href={`${ctaUrl}`} legacyBehavior>
                  <a
                    className={`${classes['footer-btn']} uppercase btn salmon no-underline text-align--center`}
                  >
                    {ctaText}
                  </a>
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BannerBlock
