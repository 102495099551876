import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { InputField } from '../InputField'
import { memo, useCallback, useState } from 'react'
import { stripHtml } from '@/utils/htmlUtils'
import { RemoveIcon } from '../Icons'
import useErrorLogging from '@/hooks/useErrorLogging'
import { dataLayerEvent } from '@/utils/dataLayer'

const DiscountForm = () => {
  const { removeDiscount, applySingleDiscount, data } =
    useHeadlessCheckoutContext()

  const orderDiscounts = data?.cart.discountCodes
    .filter((dc) => dc.applicable)
    .reduce((discounts, d) => {
      const existing = discounts.find((ex) => ex.code === d.code)
      if (!existing) discounts.push(d)
      return discounts
    }, [])

  return (
    <MemoizedDiscountForm
      currentDiscounts={orderDiscounts}
      applyDiscount={applySingleDiscount}
      removeDiscount={removeDiscount}
      errors={data.userErrors.filter((e) => e.field.includes('discountCode'))}
    />
  )
}

const MemoizedDiscountForm = memo(
  ({ currentDiscounts, applyDiscount, removeDiscount, errors }) => {
    const logError = useErrorLogging()
    const [discount, setDiscount] = useState()
    const [loading, setLoading] = useState(false)

    const hasErrors = errors && errors.length > 0

    const handleApply = useCallback(async () => {
      setLoading(true)
      try {
        await applyDiscount(discount)
        setDiscount('')
        dataLayerEvent({ event: 'apply_discount_code' })
      } finally {
        setLoading(false)
      }
    }, [discount, applyDiscount, logError])

    const handleRemoveDiscount = useCallback(async (code) => {
      try {
        await removeDiscount(code)
        dataLayerEvent({ event: 'remove_discount_code' })
      } catch (e) {
        logError('discount_code', e)
      }
    }, [])

    return (
      <div className="order-discount-form">
        <div className="discount-form">
          <InputField
            className="input discount-form__field"
            type="text"
            placeholder={'Enter discount code'}
            value={discount}
            messageText={hasErrors && stripHtml(errors[0].message)}
            messageType={hasErrors && 'alert'}
            onChange={(e) => setDiscount(e.target.value)}
            disabled={loading}
          />
          <button
            className="discount-form__btn btn sitkablue"
            disabled={loading}
            onClick={handleApply}
            loading={loading.toString()}
          >
            Apply Discount
          </button>
        </div>
        <div className="applied-discount-list">
          {currentDiscounts.map((d) => (
            <div className="applied-discount" key={`${d.code}`}>
              <button onClick={() => handleRemoveDiscount(d.code)}>
                <RemoveIcon />
              </button>
              {`${d.code}`}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

export default DiscountForm
