import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { LineItems } from '../LineItems'
import IconSelectArrow from '@/svgs/select-arrow.svg'
import { formatShopifyPrice } from '@/utils/formatPrice'
import { AccoladeCard } from '../AccoladeCard'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import useDeepCompareEffect from 'use-deep-compare-effect'
import BannerBlock from '@/components/Sections/BannerBlock'
import { useCustomerContext } from '@/context/CustomerContext'
import { getProductByHandle } from '@/utils/getProducts'
import { getCartSettings } from '@/utils/getCartSettings'
import ContinueShoppingButton from '../ContinueShoppingButton/ContinueShoppingButton'

const OrderSummary = () => {
  const [summaryOpen, setSummaryOpen] = useState(true)
  const { data, cartFlyoutOpen } = useHeadlessCheckoutContext()
  const [lineItems, setLineItems] = useState([])
  const { customer } = useCustomerContext()
  const [cartSettings, setCartSettings] = useState([])
  const [checkoutAccolades, setCheckoutAccolades] = useState([])
  const [orderSummaryBanner, setOrderSummaryBanner] = useState(undefined)

  let displayOrderSummaryBanner = false
  if (orderSummaryBanner) {
    let { image, header, ctaText, footerImage, footerText } = orderSummaryBanner
    displayOrderSummaryBanner =
      !!image || !!header || !!ctaText || !!footerImage || !!footerText
  }
  const isGiftOrder = data?.cart?.properties.is_gift_order == 'true'

  useEffect(() => {
    getCartSettings().then((cartSettingData) => {
      setCartSettings(cartSettingData)
      setCheckoutAccolades(cartSettingData.fields.checkoutAccolades)
      setOrderSummaryBanner(cartSettingData.fields.orderSummaryBanner)
    })
  }, [])

  useDeepCompareEffect(() => {
    Promise.all(
      data?.cart?.lineItems?.map(async (li) => {
        return await getProductByHandle(li.variant.product.handle)
      }),
    ).then((productData) => {
      const lineItemsWithProductData = data?.cart?.lineItems.map((li) => ({
        ...li,
        product: productData.find(
          (p) => p.content.handle === li.variant.product.handle,
        ),
      }))
      setLineItems(lineItemsWithProductData)
    })
  }, [data?.cart?.lineItems])

  return (
    <div className="order-summary">
      {checkoutAccolades.accoladeVideoUrl && (
        <div>
          <AccoladeCard checkoutAccolades={checkoutAccolades} />
        </div>
      )}
      {displayOrderSummaryBanner && (
        <div className="checkout__banner">
          <BannerBlock fields={orderSummaryBanner} />
        </div>
      )}
      {cartFlyoutOpen && <ContinueShoppingButton data={data} isLink />}
      {!cartFlyoutOpen && (
        <div className="show-on-mobile">
          <ContinueShoppingButton data={data} isLink />
        </div>
      )}
      <div
        className={`checkout__header checkout__row ${summaryOpen ? 'checkout__header--open' : 'checkout__header--closed'}`}
      >
        <div className="checkout__header-wrap">
          {isGiftOrder ? (
            <h3>Gift Order Summary</h3>
          ): <h3>Order Summary</h3> }
          <span className="checkout__header-subtotal hide-on-desktop">
            ${formatShopifyPrice(data.cart.cost.subtotalAmount.amount)}
          </span>
        </div>
        <button
          onClick={() => setSummaryOpen(!summaryOpen)}
          className="checkout__header-toggle-btn"
        >
          <IconSelectArrow />
        </button>
      </div>
      {!!summaryOpen && (
        <LineItems
          lineItems={lineItems}
          readOnly={false}
          userLoggedIn={!!customer}
          isGiftOrder={isGiftOrder}
        />
      )}
      {/* <GiftCardForm /> */}
    </div>
  )
}

export default OrderSummary
