import { useEffect } from 'react'
import { dataLayerEvent } from '@/utils/dataLayer'

const ErrorPage = ({ backToStartStep, errors }) => {
  useEffect(() => {
    if (window) window.scrollTo(0, 0)
    console.debug('checkout_error', errors)
    dataLayerEvent({ event: 'checkout_error' })
  }, [])

  return (
    <div className="checkout__error">
      <h3>Oh no, something went wrong!</h3>
      <p className="secondary--body">
        Please try again by clicking{' '}
        <button
          onClick={() => backToStartStep()}
          className="btn-link-underline"
        >
          here
        </button>{' '}
        or email salmonsupport@sitkaseafoodmarket.com for assistance
      </p>
    </div>
  )
}

export default ErrorPage
