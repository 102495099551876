import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { useRouter } from 'next/router'
import { stripHtml } from '@/utils/htmlUtils'
import { useCustomerContext } from '@/context/CustomerContext'
import { dataLayerBeginCheckout } from '@/utils/dataLayer'

const CheckoutButton = ({ disabled }) => {
  const router = useRouter()
  const { data } = useHeadlessCheckoutContext()
  const { cart, checkoutUrl, userErrors } = data
  const { customer } = useCustomerContext()

  function redirectToShopifyCheckout() {
    dataLayerBeginCheckout({ cart })
    if (customer)
      router.push(
        `/api/checkout/redirect?checkoutUrl=${encodeURIComponent(checkoutUrl)}&email=${encodeURIComponent(customer.email)}`,
      )
    else router.push(checkoutUrl)
  }

  return (
    <div className="checkout_button_wrapper">
      <button
        data-testid="checkout-button"
        type="button"
        className="checkout__cta-btn btn salmon"
        onClick={redirectToShopifyCheckout}
        disabled={disabled}
      >
        Continue to Payment
      </button>
      {disabled && <p className="checkout_error_message">Please complete recipient information</p>}
      {userErrors.length > 0 &&
        userErrors.map((e, i) => (
          <p
            data-testid="checkout-error"
            key={`checkout-error-${i}`}
            className="checkout_error_message"
          >
            {stripHtml(e.message)}
          </p>
        ))}
    </div>
  )
}

export default CheckoutButton
