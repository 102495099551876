import sanityClient from '../services/sanityClient'

const QUERY = `
*[_type == "cart"]
{
  _type,
  _rev,
  "objectID": _id,
  _createdAt,
  _updatedAt,
  title,
  handle,
  header,
  terms,
  explicitConscent,
  giftedterms,
  checkoutAccolades{
    accoladeVideoUrl,
    accoladeTitle,
    accoladeVideoAspect,
    accoladeQuote,
    accoladeImage{
      asset->{
        published,
        type,
        sha1hash,
        metadata,
        assetId,
        originalFilename,
        extension,
        uploadId,
        path,
        mimeType,
        url,
      },
    }
  },
  orderSummaryBanner{
    image{
      crop,
      hotspot,
      asset->{
        metadata,
        url
      }
    },
    header,
    subheader,
    ctaText,
    ctaUrl,
    backgroundColor,
    headerColor,
    subHeaderColor,
    footerImage{
      crop,
      hotspot,
      asset->{
        metadata,
        url
      }
    },
    footerText,
    footerSubText
  }
}
`

export const getCartSettings = async () => {
  const cartSettings = await sanityClient.fetch(QUERY)
  const cartSettingData = cartSettings.map((setting) => {
    return {
      createdAt: setting._createdAt,
      fields: {
        __typename: setting._type,
        ...setting,
      },
      handle: setting.handle.current,
      published: true,
      sourceEntryId: '',
      sourceId: setting.objectID,
      tags: [],
      title: setting.title,
      type: setting._type,
      updatedAt: setting._updatedAt,
    }
  })
  return cartSettingData[0]
}
