import { useState } from 'react'
import { DiscountForm } from '../DiscountForm'
import Plus from '@/svgs/plus.svg'

const DiscountSection = () => {
  const [discountOpen, setDiscountOpen] = useState(false)

  return (
    <>
      <div
        className= "checkout__header"
      >
        <div className="checkout__header-wrap">
          <h6>Have a discount code?</h6>
        </div>
        <button
          onClick={() => setDiscountOpen(!discountOpen)}
          className="checkout__header-toggle-btn"
        >
          <Plus />
        </button>
      </div>
      {!!discountOpen && <DiscountForm />}
    </>
  )
}

export default DiscountSection
