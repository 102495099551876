import PropTypes from 'prop-types';
import classes from './YoutubeVideo.module.scss';

const YoutubeVideo = ({ src, aspectRatio }) => {
  let ratioClass = classes['portrait'];
  if (aspectRatio === 'landscape') {
    ratioClass = classes['landscape'];
  }

  return (
    <div className={`${classes['video-wrapper']} ${ratioClass}`}>
      <iframe
        width="100%"
        height="100%"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};
{/* <iframe width="506" height="899" src="https://www.youtube.com/embed/DkcsF5bBwmA" title="How to Clean and Process Wild Halibut" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
YoutubeVideo.propTypes = {
  src: PropTypes.string,
};

export default YoutubeVideo;
