import PropTypes from 'prop-types'
import classes from './AccoladeCard.module.scss'
import { useState } from 'react'

import Image from 'next/image'
import Video from '@/components/Video'
import PlayImage from './play.png'

const AccoladeCard = ({ checkoutAccolades }) => {
  const [open, setOpen] = useState(false)
  const [transition, setTransition] = useState(false)
  const [startVideo, setStartVideo] = useState(false)

  const toggleOpen = () => {
    if (!open) {
      setTransition(true)
      setTimeout(() => {
        setOpen(true)
        setStartVideo(true)
      }, 750)
    } else {
      setTransition(false)
      setOpen(false)
    }
  }

  const onEndVideo = () => {
    setOpen(false)
    setTransition(false)
    setStartVideo(false)
  }

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      controls: 0,
    },
  }

  const ratio =
    checkoutAccolades.accoladeVideoAspect === 'portrait' ? '177.7%' : '56.25%'

  return (
    <>
      <div
        className={`${classes['accolade-card']} ${open ? classes['open'] : classes['closed']}`}
        style={{ '--video-player-aspect-ratio': ratio }}
      >
        {/* Display info */}
        {!open && (
          <div className={`${classes['accolade-info']}`}>
            <div
              className={`${classes['thumbnail-container']} ${transition ? classes['grow'] : ''}`}
              onClick={toggleOpen}
            >
              <Image
                src={checkoutAccolades.accoladeImage.asset.url}
                alt="Crab"
                layout="fill"
                objectFit="cover"
              />
              {!transition && (
                <div className={`${classes['play-button']}`}>
                  <Image
                    src={PlayImage}
                    alt="Play Video"
                    layout="intrinsic"
                    width={32}
                    height={32}
                    objectFit="contain"
                  />
                </div>
              )}
            </div>
            <div
              className={`${classes['info']} ${transition ? classes['hide'] : ''}`}
            >
              <div className={`${classes['name']}`}>
                {checkoutAccolades.accoladeTitle}
              </div>
              <div className={`${classes['subtext']}`}>
                &quot;{checkoutAccolades.accoladeQuote}&quot;
              </div>
            </div>
          </div>
        )}

        {open && (
          <div className={`${classes['video-container']}`}>
            <div className={`${classes['video-wrapper']}`}>
              <Video
                youtubeVideoId={checkoutAccolades.accoladeVideoUrl}
                opts={opts}
                startVideo={startVideo}
                onEndVideo={onEndVideo}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

AccoladeCard.propTypes = {
  src: PropTypes.string,
}

export default AccoladeCard
