import {
  Customer,
  ShippingLines,
  CheckoutButton,
  OrderSummary,
  OrderSummaryBreakdown,
  DiscountSection,
  TermsSection,
  GiftRecipient,
} from '@/components/HeadlessCheckout'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import ContinueShoppingButton from '../../ContinueShoppingButton/ContinueShoppingButton'

const StartStep = () => {
  const { data, cartFlyoutOpen } = useHeadlessCheckoutContext()

  const cart = data?.cart
  
  const isGiftInfoComplete = (cart) => {
    if (cart?.properties?.is_gift_order === 'true') {
      const recipientEmail = cart.attributes.find(att => att.key === 'recipient_email')?.value
      const recipientName = cart.attributes.find(att => att.key === 'recipient_name')?.value
      return !!(recipientEmail && recipientName)
    }
    return true
  }

  const giftInfoComplete = isGiftInfoComplete(cart)

  return (
    <>
      <div className="checkout__layout checkout__main" role="main">
        <div className="checkout__panel-wrapper">
          <div className="checkout__panel">
            <OrderSummary />
          </div>
          <div className="checkout__panel">
            <DiscountSection />
          </div>
          <div className="checkout__panel checkout__terms-panel">
            <TermsSection />
          </div>
          <div className="checkout__panel">
            <OrderSummaryBreakdown readOnly={false} />
          </div>
        </div>
        <div className="checkout__panel-wrapper">
          {!cartFlyoutOpen && (
            <div className="checkout__panel shopping-btn-mobile">
             <ContinueShoppingButton data={data} isLink/>
            </div>
          )}
          <div className="checkout__panel">
            <GiftRecipient />
          </div>
          <div className="checkout__panel">
            <Customer />
          </div>
          <div className="checkout__panel">
            <ShippingLines applicationLoading={false} />
          </div>
          <div className="checkout__navigation">
          <CheckoutButton 
              disabled={!giftInfoComplete}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default StartStep
