import Youtube from 'react-youtube'

const Video = ({youtubeVideoId, startVideo, autoplay = true, className, youtubeOptions, onEndVideo}) => {

  const onReady = (event) => {
    if (!autoplay) {
      return false
    }
    setTimeout(() => {
      if (event && event.target) {
        event.target.setVolume(10);
        event.target.playVideo();
      }
    }, 1000)
  }

  const onEnd = (event) => {
    if (!!onEndVideo) {
      setTimeout(() => {
        onEndVideo();
      }, 1000)
    }
  }

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      controls: 0,
      // https://developers.google.com/youtube/player_parameters
      rel: 0,
      modestbranding: 1
    },
    ...youtubeOptions
  }

  if (!startVideo) {
    return ''
  }

  return (
    <div className={className}>
      <Youtube videoId={youtubeVideoId} opts={opts} onReady={(e) => onReady(e)} onEnd={(e) => onEnd(e)}/>
    </div>
  )
}

export default Video
