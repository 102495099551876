import { useCallback, useEffect, useMemo, useState } from 'react';
import SavedAddress from './SavedAddress';
import Checkbox from "react-custom-checkbox";
import classes from './SavedAddressList.module.scss';

const SavedAddressList = ({
  savedAddresses,
  selectedAddress,
  disabled,
  onChange,
  errors
}) => {
  const [prevAddress, setPrevAddress] = useState(null);

  useEffect(() => {
    if (selectedAddress) {
      setPrevAddress(selectedAddress);
    }
  }, [selectedAddress]);

  const lastSelectedAddress = useMemo(() => {
    if (prevAddress) {
      return savedAddresses.find((address) => address.id === prevAddress);
    } else {
      return savedAddresses ? savedAddresses[0] : {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevAddress]);

  const handleNewAddressChange = useCallback(() => {
    onChange({
      ...lastSelectedAddress,
      id: null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedAddress]);

  if (!savedAddresses || savedAddresses.length === 0) return null;

  const savedAddressList = savedAddresses.map((address, index) => {
    return (
      <div key={index} className={`checkout__radio-wrapper ${selectedAddress === address.id ? 'is-selected' : ''}`}>
        <Checkbox
          className="checkout__radio"
          icon={<div className="radio--checked"></div>}
          label={<SavedAddress addressInfo={address} />}
          checked={selectedAddress === address.id}
          value="shipping-addres"
          disabled={disabled}
          onChange={() => {
            onChange(address);
          }}
        />
      </div>
    );
  });

  return (
    <>
      <div className="checkout__radio-list shipping-address-selector">
        {savedAddressList}
        <div className={`checkout__radio-wrapper ${!selectedAddress ? 'is-selected' : ''}`}>
          <Checkbox
            className="checkout__radio"
            icon={<div className="radio--checked"></div>}
            label={'Add a new address'}
            checked={!selectedAddress}
            value="shipping-addres"
            disabled={disabled}
            onChange={handleNewAddressChange}
          />
        </div>
      </div>
      {errors && errors.length && selectedAddress ? <p className={classes['saved-address-validation-error']}>
        Your selected address is invalid. Please select another address or manually enter a new address.
      </p> : null}
    </>
  );
};

export default SavedAddressList;
