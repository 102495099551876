import Link from 'next/link'

const TermsSection = () => {
  return (
    <>
      <div className="checkout__discount-wrap">
        <p>
          By making this purchase, I agree to these{' '}
          <Link href="/pages/terms-and-conditions">terms and conditions</Link>.
        </p>
      </div>
    </>
  )
}

export default TermsSection
